import { useEffect, useRef } from 'react'
import usePrevious from './usePrevious';


export default (f, dependencies) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }
    
    return f && f();
  }, dependencies)
}
