export const RADIUS_OPTIONS = [
  { value: 0.5, label: '0.5 miles' },
  { value: 1, label: '1 miles' },
  { value: 1.5, label: '1.5 miles' },
  { value: 2, label: '2 miles' },
]

export const MAP_DEFAULT_OPTIONS = {
  fullscreenControl: true,
  streetViewControl: false,
  mapTypeControl: false,
  scaleControl: true,
};

export const MAP_LIBRARIES = ["places"];
