import React from 'react';

import classNames from 'classnames';
import { classModifier } from 'utils';

import './Marker.scss';
import Circle from '../Circle/Circle';
import ICONS from 'assets/icons';
import Tooltip from '../Tooltip/Tooltip';


const Marker = (props) => {
  const {
    lng,
    lat,
    children,
    tooltip,
    className,
    radius,
    zoom,
    extraTooltipCondition,
    onClick,
    isSmall,
    ...restProps
  } = props;

  const lngNumber = Number(lng);
  const latNumber = Number(lat);

  const classes = classNames(
    className,
    classModifier('geo-marker', [
      onClick && 'clickable',
      isSmall && 'small',
    ]),
  );

  return (
    <div
      className={classes}
      key={extraTooltipCondition ? 'extra' : 'default'}
      onClick={onClick}
      {...restProps}
    >
      <Tooltip
        className="geo-marker__tooltip"
        text={tooltip}
        interactive
        extraCondition={extraTooltipCondition}
      >
        <ICONS.marker className={classModifier('geo-marker__svg', !children && 'empty')} />
      </Tooltip>

      <div className="geo-marker__content">
        {children}
      </div>

      {restProps.selected && <ICONS.marker className='geo-marker__selected-svg' />}
      {restProps.isBufferedGirl && <ICONS.buffer className='geo-marker__buffer-svg' />}

      {!!radius && (
        <Circle
          className="geo-marker__circle"
          lat={latNumber}
          lng={lngNumber}
          radius={radius}
          zoom={zoom}
        />
      )}
    </div>
  )
}

export default Marker;
