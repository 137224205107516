import { useEffect, useState } from 'react';

import API from 'api/api';
import GirlsMapModalSelectFilter from './GirlsMapModalSelectFilter';
import { useSelector } from 'react-redux';
import { useDidUpdate } from 'hooks';

const FILTERS_LIST_DATA = [
  { name: 'country',
    request: API.getCountriesOptionsForGirlsMap,
    requiredFilter: null,
  },
  {
    name: 'city',
    request: API.getCitiesOptionsForGirlsMap,
    requiredFilter: 'country',
    requiredField: 'country',
  },
  {
    name: 'area',
    request: API.getLocationsOptionsForGirlsMap,
    requiredFilter: 'city',
    requiredField: 'city',
  },
];

const GirlsMapModalFilters = ({ flyTo, getGirlsCoordinatesByBounds }) => {
  const { location } = useSelector((state) => state.map);

  useDidUpdate(() => {
    const { country, city, area } = location;
    let coordinates = null;
    let zoom = 12;
    

    getGirlsCoordinatesByBounds(true);

    if (area) {
      coordinates = { lat: parseFloat(area.latitude), lng: parseFloat(area.longitude) };
      zoom = 14;
    } else if (city) {
      coordinates = { lat: parseFloat(city.latitude), lng: parseFloat(city.longitude) };
      zoom = 11;
    } else if (country) {
      coordinates = { lat: parseFloat(country.latitude), lng: parseFloat(country.longitude) };
      zoom = 6;
    }

    if (!!coordinates) {
      flyTo(coordinates, zoom);
    }
  }, [location]);

  return (
    <div className="girls-map__filters">
      {FILTERS_LIST_DATA.map((filter) => (
        <GirlsMapModalSelectFilter
          key={filter.name}
          name={filter.name}
          request={filter.request}
          requiredField={filter.requiredField}
          requiredFilter={filter.requiredFilter}
          isDisabled={!!filter.requiredFilter && !location[filter.requiredFilter]}
        />
      ))}
    </div>
  )
};

export default GirlsMapModalFilters;
